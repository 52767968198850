import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"

const Customers = ({ data }) => {
  return (
    <div className="container">
      <div className="row align-items-center justify-content-center">
        <div className="col-6 col-sm-4 col-md-2 mb-4 mb-md-0">
          <div className="img-fluid text-gray-600 mb-2 mb-md-0">
            <a
              href="https://www.strabag.de"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Img fluid={data.logoSTRABAG.childImageSharp.fluid} />
            </a>
          </div>
        </div>
        <div className="col-6 col-sm-4 col-md-2 mb-4 mb-md-0">
          <Img fluid={data.logoNavVis.childImageSharp.fluid} />
        </div>
        <div className="col-6 col-sm-4 col-md-2 mb-4 mb-md-0">
          <Img fluid={data.logoInovitas.childImageSharp.fluid} />
        </div>
        <div className="col-6 col-sm-4 col-md-2 mb-4 mb-md-0">
          <Img fluid={data.logoHorus.childImageSharp.fluid} />
        </div>
        <div className="col-6 col-sm-4 col-md-2 mb-4 mb-md-0">
          <Img fluid={data.logoEsri.childImageSharp.fluid} />
        </div>
        <div className="col-6 col-sm-4 col-md-2 mb-4 mb-md-0">
          <Img fluid={data.logoChcnav.childImageSharp.fluid} />
        </div>
      </div>

      <div className="row justify-content-center mt-3">
        <Link
          className="text-muted"
          style={{ fontSize: "0.85em" }}
          to="/customers/"
        >
          Trusted by industry leaders
        </Link>
      </div>
    </div>
  )
}

export const logo = graphql`
  fragment logoCustomersLandscape on File {
    childImageSharp {
      fluid(maxWidth: 250) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  fragment logoCustomersPortrait on File {
    childImageSharp {
      fixed(height: 100) {
        ...GatsbyImageSharpFixed
      }
    }
  }
`

const customer =  props => (
  <StaticQuery
    query={graphql`
      query {
        logoSTRABAG: file(relativePath: { eq: "STRABAG_Logo_256h.png" }) {
          ...logoCustomersLandscape
        }
        logoInovitas: file(relativePath: { eq: "inovitas-logo.png" }) {
          ...logoCustomersLandscape
        }
        logoNavVis: file(relativePath: { eq: "navvis-logo.png" }) {
          ...logoCustomersLandscape
        }
        logoChcnav: file(relativePath: { eq: "chcnav-logo.png" }) {
          ...logoCustomersLandscape
        }
        logoEsri: file(relativePath: { eq: "esri-startup-horizontal.jpg" }) {
          ...logoCustomersLandscape
        }
        logoHorus: file(relativePath: { eq: "horus-logo.png" }) {
          ...logoCustomersLandscape
        }
      }
    `}
    render={data => <Customers data={data} {...props} />}
  />
)

export default customer;