import React from "react"
import Img from "gatsby-image"
import PropTypes from "prop-types"

const TestimonialCard = ({ text, company, author, role, image }) => {
  let altTextLogo = company + " logo"
  return (
    <>
      <div className="card shadow-light-lg mb-5">
        <div className="row no-gutters align-items-center">
          <div className="col-12 col-md-7 order-md-2">
            <div className="card-body">
              <blockquote className="blockquote mb-0 text-center">
                <p className="text-small" style={{ fontSize: "0.90em" }} dangerouslySetInnerHTML={{ __html: text }}></p>
              </blockquote>
            </div>
          </div>

          <div className="col-12 col-md-5 bg-cover text-center justify-content-center order-md-1">
            <Img
              fluid={image}
              alt={altTextLogo}
              className="img-fluid d-block mx-auto mb-5"
              style={{ maxWidth: "250px" }}
            />
            <p className="font-size-lg font-weight-bold mb-0">{author}</p>
            <p className="font-size-sm text-muted mb-3">
              {role}
              <br />
              {company}
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

TestimonialCard.propTypes = {
  text: PropTypes.node.isRequired,
  company: PropTypes.node,
  author: PropTypes.node,
  role: PropTypes.node,
  image: PropTypes.node,
}

export default TestimonialCard
