import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons"

const Demo = () => {
  return (
    <div className="section py-8">
      <div className="container">
        <div className="row justify-content-between align-items-center mb-5">
          <div className="col-12 col-md-7 order-md-1">
            <h2
              className="text-primary font-weight-bold"
              style={{ maxWidth: "450px" }}
            >
              Start with a free Demo
            </h2>
          </div>
          <div className="col-12 col-md-4 order-md-2 text-center">
            <a
              href="https://app.celantur.com/"
              className="btn btn-success lift event-start-demo"
            >
              Start Demo{" "}
              <FontAwesomeIcon icon={faArrowRight} className="ml-3" />
            </a>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-4">
            <div className="row">
              <div className="col-auto col-md-12">
                <div className="row no-gutters align-items-center mb-md-5">
                  <div className="col-auto">
                    <span className="badge badge-lg badge-rounded-circle badge-primary-soft">
                      <span>1</span>
                    </span>
                  </div>
                  <div className="col">
                    <hr className="d-none d-md-block border-gray-900-50 mr-n7" />
                  </div>
                </div>
              </div>
              <div className="col col-md-12 ml-n5 ml-md-0">
                <h3 className="text-primary">Sign up</h3>
                <p className="text-muted-80 mb-6 mb-md-0">
                  Get access to our platform.
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className="row">
              <div className="col-auto col-md-12">
                <div className="row no-gutters align-items-center mb-md-5">
                  <div className="col-auto">
                    <span className="badge badge-lg badge-rounded-circle badge-primary-soft">
                      <span>2</span>
                    </span>
                  </div>
                  <div className="col">
                    <hr className="d-none d-md-block border-gray-900-50 mr-n7" />
                  </div>
                </div>
              </div>
              <div className="col col-md-12 ml-n5 ml-md-0">
                <h3 className="text-primary">Anonymize Data</h3>
                <p className="text-muted-80 mb-6 mb-md-0">
                  Get your images and videos anonymized.
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className="row">
              <div className="col-auto col-md-12">
                <div className="row no-gutters align-items-center mb-md-5">
                  <div className="col-auto">
                    <span className="badge badge-lg badge-rounded-circle badge-primary-soft">
                      <span>3</span>
                    </span>
                  </div>
                </div>
              </div>
              <div className="col col-md-12 ml-n5 ml-md-0">
                <h3 className="text-primary">Expert advice</h3>
                <p className="text-muted-80 mb-0">
                  Our experts will provide custom advice for applying automated anonymization on your use case.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Demo
