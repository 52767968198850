import React from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSmile } from "@fortawesome/free-solid-svg-icons"

const FeatureColumn = ({ headline, icon, iconColor, children }) => {
  return (
    <>
      <div className="col-12 col-md-4 text-center">
        <div className="icon text-primary mb-3 text-center">
          <FontAwesomeIcon
            icon={icon ? icon : faSmile}
            size="3x"
            style={{ color: iconColor ? iconColor : "#4285f4" }}
          />
        </div>
        <h3 className="font-weight-bold">{headline}</h3>
        <p className="text-muted mp-6 mp-md-0">{children}</p>
      </div>
    </>
  )
}

FeatureColumn.propTypes = {
  headline: PropTypes.node.isRequired,
  icon: PropTypes.object.isRequired,
  iconColor: PropTypes.node,
  children: PropTypes.node.isRequired,
}

export default FeatureColumn
