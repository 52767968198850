import React from "react"
import { StaticQuery, graphql } from "gatsby"
import TestimonialCard from "../components/testimonial-card"

const Testimonials = ({
  data,
  all = false,
  riegl = false,
  allTerraDS = false,
  camenAI = false,
  orbis360 = false,
  qapture = false,
  horus = false,
  navvis = false,
}) => {
  let textRiegl =
    '"With a GDPR inflicted raise of data privacy awareness, anonymization has become a critical task in the processing chain of remote sensing data.<br />The Celantur solution proved to be a reliable tool for the various kinds of imagery collected with RIEGL laser scanning systems. Even under difficult light conditions the software achieved an impressive detection rate."'

  let textAllTerraDS =
    '"Celantur has helped us win well-known customers for our mobile mapping business. Their solution has made us and our users completely convinced. The cooperation with Celantur is always goal-oriented and easy-going. Simply the best solution on the market!"'

  let textQapture =
    '"With Celantur we have found a state-of-the-art solution for getting our mobile mapping solutions GDPR compliant. Their platform is easy to use, fast and the most reliable automated blurring tool we know - and we’ve tried a lot. Thanks to them we are finally able to focus fully on our core product again!"'

  let textCamenAI =
    '"Celantur is our partner in making our platform GDPR compliant. Both on technical as well as business level we have a good click and we created a scalable solution that will be beneficial for both of us."'

  let textOrbis360 =
    '"Effiziente und zuverlässige Anonymisierungslösung für verschiedene Bildformate. Unkompliziertes Handling, freundlicher Service, faire Preise."'

  let textHorus =
    '"Horus has been using the Celantur blurring tool for a few months now and we\'re very happy with the results. The tool is incredibly easy to integrate and to use. The ability to quickly and easily blur sensitive information has saved us a lot of time and effort in post-processing our data. Overall, we highly recommend the Celantur blurring tool to anyone who needs to protect sensitive information in their data."'

  let textNavvis =
    '"Our customers operate all over the globe and require solutions that meet the data privacy requirements that customers have come to expect in the industry. Celantur has enabled us to roll out a global solution, which we can expand and adapt according to our customers\' needs. We are confident that they are the partner with whom we will be able to tackle scenarios we need to meet, no matter how complex."'

  let showRiegl = all || riegl
  let showAllTerraDS = all || allTerraDS
  let showCamenAI = all || camenAI
  let showOrbis360 = all || orbis360
  let showQapture = all || qapture
  let showHorus = all || horus
  let showNavvis = all || navvis

  return (
    <section className="py-5 bg-gray-200">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-8 text-center mb-8">
            <h2 className="font-weight-bold">Testimonials</h2>
            <p className="font-size-lg text-center text-muted mb-3">
              Trusted by industry-leaders. Here's what they say about Celantur.
            </p>
          </div>
        </div>

        {showNavvis && (
          <TestimonialCard
            company="NavVis GmbH"
            text={textNavvis}
            author="Georg Schroth"
            role="CTO and Co-founder"
            image={data.logoNavvis.childImageSharp.fluid}
          />
        )}

        {showRiegl && (
          <TestimonialCard
            company="RIEGL Laser Measurement Systems GmbH"
            text={textRiegl}
            author="DI Christian Sevcik"
            role="Manager Strategic Software Alliances"
            image={data.logoRiegl.childImageSharp.fluid}
          />
        )}

        {showHorus && (
          <TestimonialCard
            company="Horus"
            text={textHorus}
            author="Bas Beukers"
            role="Director Business Development & Marketing"
            image={data.logoHorus.childImageSharp.fluid}
          />
        )}

        {showAllTerraDS && (
          <TestimonialCard
            company="AllTerra DS"
            text={textAllTerraDS}
            author="Dipl.Ing. (FH) Wolfgang Probst"
            role="CEO"
            image={data.logoAllTerraDSLarge.childImageSharp.fluid}
          />
        )}

        {showCamenAI && (
          <TestimonialCard
            company="CamenAI B.V."
            text={textCamenAI}
            author="Sander Vlotman"
            role="Managing Director"
            image={data.logoCamenAI.childImageSharp.fluid}
          />
        )}

        {showOrbis360 && (
          <TestimonialCard
            company="orbis360 SA"
            text={textOrbis360}
            author="Barbara Thaler"
            role="Lead 3D Geodata Management"
            image={data.logoOrbis360.childImageSharp.fluid}
          />
        )}

        {showQapture && (
          <TestimonialCard
            company="qapture GmbH"
            text={textQapture}
            author="Daniel Höller"
            role="CEO"
            image={data.logoQapture.childImageSharp.fluid}
          />
        )}
      </div>
    </section>
  )
}

export const logo = graphql`
  fragment logoTestimonials on File {
    childImageSharp {
      fluid(maxWidth: 300) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

const testimonial = (props) => (
  <StaticQuery
    query={graphql`
      query {
        logoAllTerraDSLarge: file(relativePath: { eq: "allterra-ds-logo.jpg" }) {
          ...logoTestimonials
        }
        logoQapture: file(relativePath: { eq: "qapture.png" }) {
          ...logoTestimonials
        }
        logoRiegl: file(relativePath: { eq: "riegl-logo.png" }) {
          ...logoTestimonials
        }
        logoCamenAI: file(relativePath: { eq: "camenai-logo.png" }) {
          ...logoTestimonials
        }
        logoOrbis360: file(relativePath: { eq: "orbis360-logo.png" }) {
          ...logoTestimonials
        }
        logoHorus: file(relativePath: { eq: "horus-logo.png" }) {
          ...logoTestimonials
        }
        logoNavvis: file(relativePath: { eq: "navvis-logo.png" }) {
          ...logoTestimonials
        }
      }
    `}
    render={(data) => <Testimonials data={data} {...props} />}
  />
)

export default testimonial
