import React from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck } from "@fortawesome/free-solid-svg-icons"

const DataProtectionMeasures = () => {
  return (
    <section className="py-8 pt-md-9 pb-md-8 bg-gray-800">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-8 text-center">
            <h2 className="font-weight-bold text-white">
              Trust and Compliance
            </h2>

            <p className="font-size-lg text-gray-500 mb-7 mb-md-9">
              Data Protection is our core business. That's why we have strong
              measures in place to comply with the GDPR and other data
              protection laws.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="d-flex">
              <div className="badge badge-rounded-circle badge-success mt-1 mr-4">
                <FontAwesomeIcon icon={faCheck} />
              </div>
              <p className="text-gray-300">
                Images are processed in GDPR-certified data centers in the
                European Union
              </p>
            </div>
            <div className="d-flex">
              <div className="badge badge-rounded-circle badge-success mt-1 mr-4">
                <FontAwesomeIcon icon={faCheck} />
              </div>
              <p className="text-gray-300">
                External Data Protection Officer at service
              </p>
            </div>
            <div className="d-flex">
              <div className="badge badge-rounded-circle badge-success mt-1 mr-4">
                <FontAwesomeIcon icon={faCheck} />
              </div>
              <p className="text-gray-300">
                All data and storage devices are encrypted
              </p>
            </div>
            <div className="d-flex">
              <div className="badge badge-rounded-circle badge-success mt-1 mr-4">
                <FontAwesomeIcon icon={faCheck} />
              </div>
              <p className="text-gray-300">
                Technical and Organizational Measures
                (TOM), Data Protection Impact Assessment (DPIA), Records of Processing Activities, etc.
              </p>
            </div>
            <div className="d-flex">
              <div className="badge badge-rounded-circle badge-success mt-1 mr-4">
                <FontAwesomeIcon icon={faCheck} />
              </div>
              <p className="text-gray-300">Regular &amp; unannounced Data Protection Audits</p>
            </div>
            
            <div className="text-center mt-3">
            <Link
              to="/data-protection-gdpr-compliance/"
              role="button"
              className="btn btn-sm btn-secondary my-2 event-contact"
            >
              Read More
            </Link></div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default DataProtectionMeasures
